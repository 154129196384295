<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-recalculo">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Recalcular Turno</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Observación</label>
              <textarea
                v-model="form.observacion"
                class="form-control form-control-sm"
                :class="
                  $v.form.observacion.$invalid ? 'is-invalid' : 'is-valid'
                "
              ></textarea>
              <div class="error" v-if="!$v.form.observacion.required">
                Ingrese una Observación
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form.$invalid"
              @click="recalcularTurno()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "TifConciliacionesRecalculoTurno",
  data() {
    return {
      form: {},
    };
  },
  validations: {
    form: {
      observacion: {
        required,
        minLength: minLength(10),
      },
    },
  },
  methods: {
    recalcularTurno() {
      this.$parent.cargando = true;
      if (!this.$v.form.$invalid) {
        axios({
          method: "POST",
          url: "/api/tif/gps/turno/" + this.form.turnoId,
          data: this.form,
        })
          .then(() => {
            this.form = {};
            this.$refs.closeModal.click();
            this.$parent.getIndex(this.$parent.page);
            this.$parent.cargando = false;
            this.$swal({
              icon: "success",
              title: "El  turno se recalculo correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    llenar_modal(idTurno) {
      this.form.turnoId = idTurno;
    },
  },
};
</script>
